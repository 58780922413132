<template>
    <div id="app" v-cloak>
        <b-navbar sticky light toggleable="md">
            <div class="d-flex w-100 justify-content-between">
                <b-navbar-brand>
                    <a class="navbar-brand" href="/#/homepage">
                        <img src="https://www.onesecondbefore.com/images/osb-logo.svg" width="120" alt="onesecondbefore.com logo">
                    </a>
                </b-navbar-brand>
                <b-navbar-toggle target="nav-text-collapse" v-if="project && projects.length > 0"></b-navbar-toggle>
                <b-collapse id="nav-text-collapse" is-nav v-if="project && projects.length > 0">
                    <b-navbar-nav v-if="project && projects.length > 0">
                        <b-nav-item-dropdown :text="'Project: ' + project.name">
                            <b-dropdown-group v-for="(pc, client) in projectsPerClient" :key="client" :header="client">
                                <b-dropdown-item v-for="p in pc" @click="selectProject(p)" :key="p.name" :active="project.name === p.name">
                                    {{ p.name }}
                                </b-dropdown-item>
                            </b-dropdown-group>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>

                    <b-navbar-nav class="ml-auto">
                        <b-nav-item-dropdown right text="Analysis" v-if="
                            checkAuthorization('analyst', 'capture') || checkAuthorization('analyst', 'consent') || checkAuthorization('analyst', 'analytics') ||
                            checkAuthorization('analyst', 'buying-mode-detection') || checkAuthorization('analyst', 'cross-device-matching') || checkAuthorization('analyst', 'data-driven-micro-attribution')"
                            :toggle-class="{ 'active': $route.path.startsWith('/analysis') }">

                            <b-dropdown-item v-for="d in dashboards" :to="'/dashboard/' + d.product + '/' + d.category.toLowerCase()" exact :key="d.product + '/' + d.category">{{ d.category }}</b-dropdown-item>

                            <b-dropdown-divider v-if="checkAuthorization('analyst', 'analytics') ||
                            checkAuthorization('analyst', 'buying-mode-detection') || checkAuthorization('analyst', 'cross-device-matching') || checkAuthorization('analyst', 'data-driven-micro-attribution')"></b-dropdown-divider>

                            <b-dropdown-item v-if="checkAuthorization('analyst', 'analytics')" to="/analysis/journey-analytics">Journey Analytics</b-dropdown-item>
                            <b-dropdown-item v-if="checkAuthorization('analyst', 'analytics')" to="/analysis/ad-analytics">Ad Analytics</b-dropdown-item>
                            <b-dropdown-item v-if="checkAuthorization('analyst', 'analytics')" to="/analysis/results">Results</b-dropdown-item>
                            <b-dropdown-item v-if="checkAuthorization('analyst', 'buying-mode-detection')" to="/analysis/buying-mode-detection">Buying Mode Detection</b-dropdown-item>
                            <b-dropdown-item v-if="checkAuthorization('analyst', 'cross-device-matching')" to="/analysis/cross-device-matching">Cross-Device Matching</b-dropdown-item>
                            <b-dropdown-item v-if="checkAuthorization('analyst', 'data-driven-micro-attribution')" to="/analysis/data-driven-micro-attribution">Data-Driven Micro Attribution</b-dropdown-item>

                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown right text="Management" v-if="checkAuthorization('manager', 'consent') || checkAuthorization('manager', 'transfer')"
                            :toggle-class="{ 'active': $route.path.startsWith('/management') }">
<!--                            <b-dropdown-item v-if="checkAuthorization('manager', 'consent')" to="/management/consent" exact>Consent</b-dropdown-item>-->
                            <b-dropdown-item v-if="checkAuthorization('manager', 'consent')" to="/management/consent" exact>Consent</b-dropdown-item>
                            <b-dropdown-item v-if="checkAuthorization('manager', 'transfer')" to="/management/transfer/jobs" exact>Transfer</b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown right text="Usage" v-if="checkAuthorization('analyst', 'capture') || checkAuthorization('analyst', 'transfer')"
                            :toggle-class="{ 'active': $route.path.startsWith('/usage') }">
                            <b-dropdown-item v-if="checkAuthorization('analyst', 'capture')" to="/usage/capture" exact>Analytics</b-dropdown-item>
                            <b-dropdown-item v-if="checkAuthorization('analyst', 'transfer')" to="/usage/transfer" exact>Transfer</b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown right v-if="email">
                            <template v-if="picture" v-slot:button-content>
                                <img :src="picture" class="img-circle avatar" :title="email" alt="Avatar" onerror="this.src = '/images/portrait_placeholder.png'" v-b-tooltip.bottomleft referrerpolicy="origin">
                            </template>
                            <template v-else v-slot:button-content>
                                <img src="/images/portrait_placeholder.png" alt="Avatar" class="img-circle avatar">
                            </template>
                            <b-dropdown-item @click.prevent="logout">Logout</b-dropdown-item>
                        </b-nav-item-dropdown>
<!--                        <b-nav-text>-->
<!--                            <i class="bx bx-share-alt mt-3" style="cursor: pointer" v-b-tooltip="'Copy link of this page to the clipboard'" @click="share()"></i>-->
<!--                        </b-nav-text>-->
                    </b-navbar-nav>
                </b-collapse>
            </div>
        </b-navbar>

        <router-view/>
        <spinner></spinner>
        <div id="git">{{ GIT_COMMIT }}</div>
    </div>
</template>

<script>
import {getData, postData} from '@/lib/load_data';
import Spinner from './components/spinner';
import GIT_COMMIT from './assets/git-commit.json';
import {mapFields} from "vuex-map-fields";

export default {
    name: 'App',
    components: {
        Spinner
    },
    data() {
        return {
            projects: [],
            dashboards: [],
            GIT_COMMIT: GIT_COMMIT?.substring(0, 8) || 'unknown'
        }
    },
    mounted() {
        if (this.token !== null) {
            this.loadProjects();
        }
    },
    computed: {
        ...mapFields(['email', 'project', 'products', 'picture', 'token', 'permissions']),
        projectsPerClient() {
            let result = {};
            if (this.projects) {
                this.projects.map((project) => {
                    if (!result[project.company_name]) {
                        result[project.company_name] = [];
                    }
                    result[project.company_name].push(project);
                });
            }
            return result;
        }
    },
    watch: {
        token() {
            this.loadProjects();
        },
        project(value) {
            this.loadProducts(value);
            this.loadDashboards(value);
        }
    },
    methods: {
        logout() {
            this.$router.push('/logout');
        },
        checkAuthorization(role, product) {
            return (this.project?.permissions?.includes('admin') || this.project?.permissions?.includes('manager') || this.project?.permissions?.includes(role)) && this.products?.includes(product);
        },
        selectProject(project) {
            window.localStorage.setItem('project', project.name);
            this.$store.commit('project', project);
        },
        async loadProjects() {
            this.projects = await getData('/api/projects');

            const search = new URLSearchParams(location.search);

            let project = null;
            if (this.projects !== null && this.projects.length > 0) {
                let accountParam = search.get('project');
                if (!accountParam) {
                    accountParam = window.localStorage.getItem('project');
                }
                if (accountParam) {
                    for (let i = 0; i < this.projects.length; i++) {
                        if (this.projects[i].name === accountParam) {
                            project = this.projects[i];
                            break;
                        }
                    }
                }

                if (project === null) {
                    project = this.projects[0];
                }
            }

            if (project === null) {
                this.error = 'Invalid project';
            } else {
                window.localStorage.setItem('project', project.name);
                this.$store.commit('project', project);
            }

            const refresh = search.get('refresh');
            if (refresh) {
                window.setTimeout(async () => {
                    const json = await postData('/api/refresh')
                    if (json && json.token) {
                        this.$store.commit('token', json.token);
                        location.reload();
                    }
                }, parseInt(refresh) * 1000);
            }
        },
        async loadProducts(project) {
            if (project) {
                this.products = await postData('/api/products', {
                    project: project.name
                });
            }
        },
        async loadDashboards(project) {
            if (project) {
                this.dashboards = await postData('/api/hex/dashboards', {
                    project: project.name
                });
            }
        },
        // share() {
        //     if (this.project) {
        //         const url = window.location;
        //         const search = new URLSearchParams();
        //         search.set('project', this.project.name);
        //         url.search = search.toString();
        //         navigator.clipboard.writeText(url.href).then(() => {
        //             this.$bvToast.toast('Copied link of current page to clipboard.', {
        //                 title: 'Share',
        //                 autoHideDelay: 5000,
        //                 solid: true
        //             });
        //         });
        //     }
        // }
    }
}
</script>