import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import { extend } from 'vee-validate';
import { required, regex } from 'vee-validate/dist/rules';

import App from './App.vue';

import {
    AlertPlugin,
    ButtonPlugin,
    CardPlugin,
    DropdownPlugin,
    FormCheckboxPlugin,
    FormDatepickerPlugin,
    FormGroupPlugin,
    FormInputPlugin,
    FormRadioPlugin,
    FormSelectPlugin,
    InputGroupPlugin,
    ModalPlugin,
    NavbarPlugin,
    PaginationPlugin,
    SpinnerPlugin,
    TablePlugin,
    TabsPlugin, ToastPlugin,
    TooltipPlugin
} from 'bootstrap-vue';

import mitt from 'mitt';

import { state } from './state';
import mutations from './mutations';
import router from './router';

import './styles/console.scss';

Vue.use(AlertPlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(InputGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(NavbarPlugin);
Vue.use(PaginationPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TablePlugin);
Vue.use(TabsPlugin);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);
Vue.use(VueRouter);
Vue.use(Vuex);

Vue.config.productionTip = false;

const store = new Vuex.Store({
    strict: true,
    state,
    getters: {
        getField
    },
    mutations: {
        ...mutations,
        updateField
    }
});

window.emitter = new mitt();

extend('regex', {
    ...regex,
    message: '{_field_} is invalid.'
});
extend('required', {
    ...required,
    message: (fieldName) => {
        return `${fieldName} is required.`
    }
});
extend('url', value => {
    if (!value || /^https?:\/\/[a-z0-9]+(.[a-z0-9]{2,})+(\/[a-z0-9_]*)*(\?(a-z0-9+=_%)*)?$/ig.test(value)) {
        return true;
    }
    return '{_field_} is not a valid URL';
});

new Vue({
    router,
    store: store,
    render: h => h(App),
}).$mount('#app');
