import { state } from '@/state';
import router from '@/router';
import emitter from './emitter';
import { ENDPOINT_URL } from '@/config';

export async function postData(endpoint, data) {
    if (state.token) {
        emitter.emit('spinner:show');
        return await fetch(endpoint.startsWith('https://') || endpoint.startsWith('http://') ? endpoint : ENDPOINT_URL + endpoint, {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Bearer ' + state.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(async (response) => {
            emitter.emit('spinner:hide');
            if (response.status === 200 || response.status === 404) {
                return response.json();
            } else if (response.status === 401 || response.status === 403) {
                await router.push('/login');
            } else if (response.status === 500) {
                const error = (await response.json()).error;
                alert(error);
            }
            return [];
        }).catch((error) => {
            emitter.emit('spinner:hide');
            alert(error);
            return [];
        });
    } else {
        await router.push('/login');
        return [];
    }
}

export async function postDataUnauthorized(endpoint, data) {
    emitter.emit('spinner:show');
    return await fetch(endpoint.startsWith('https://') || endpoint.startsWith('http://') ? endpoint : ENDPOINT_URL + endpoint, {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8'
        }
    }).then((response) => {
        emitter.emit('spinner:hide');
        return response.json();
    }).catch((error) => {
        emitter.emit('spinner:hide');
        alert(error);
        return null;
    });
}

export async function getData(endpoint) {
    if (state.token) {
        emitter.emit('spinner:show');

        const headers = {
            'Accept': 'application/json'
        };

        /* Only add Authorization header if GETing data from ourselves */
        if (!endpoint.startsWith('https://') && !endpoint.startsWith('http://')) {
            headers['Authorization'] = 'Bearer ' + state.token;
        }

        return await fetch(endpoint.startsWith('https://') || endpoint.startsWith('http://') ? endpoint : ENDPOINT_URL + endpoint, {
            method: 'get',
            headers
        }).then((response) => {
            emitter.emit('spinner:hide');
            if (response.status === 401 || response.status === 403) {
                router.push('/login');
                return null;
            } else {
                return response.json();
            }
        }).catch((error) => {
            emitter.emit('spinner:hide');
            alert(error);
            return null;
        });
    } else {
        await router.push('/login');
        return [];
    }
}


export async function getDataUnauthorized(endpoint) {
    emitter.emit('spinner:show');

    const headers = {
        'Accept': 'application/json'
    };

    return await fetch(endpoint.startsWith('https://') || endpoint.startsWith('http://') ? endpoint : ENDPOINT_URL + endpoint, {
        method: 'get',
        headers,
        credentials: 'omit'
    }).then((response) => {
        emitter.emit('spinner:hide');
        if (response.status === 401 || response.status === 403) {
            router.push('/login');
            return null;
        } else {
            return response.json();
        }
    }).catch((error) => {
        emitter.emit('spinner:hide');
        alert(error);
        return null;
    });
}

export async function deleteData(endpoint, data) {
    if (state.token) {
        emitter.emit('spinner:show');

        const headers = {
            'Accept': 'application/json'
        };

        /* Only add Authorization header if DELETEing data from ourselves */
        if (!endpoint.startsWith('https://') && !endpoint.startsWith('http://')) {
            headers['Authorization'] = 'Bearer ' + state.token;
        }

        if (data) {
            headers['Content-Type'] = 'application/json; charset=utf-8';
        }

        return await fetch(endpoint.startsWith('https://') || endpoint.startsWith('http://') ? endpoint : ENDPOINT_URL + endpoint, {
            method: 'delete',
            body: data ? JSON.stringify(data) : undefined,
            headers
        }).then((response) => {
            emitter.emit('spinner:hide');
            if (response.status === 401 || response.status === 403) {
                router.push('/login');
                return null;
            } else {
                return response.json();
            }
        }).catch((error) => {
            emitter.emit('spinner:hide');
            alert(error);
            return null;
        });
    } else {
        await router.push('/login');
        return null;
    }
}

export async function downloadData(path) {
    if (state.token) {
        console.log(`Downloading ${ENDPOINT_URL}${path}`);
        window.emitter.emit('show_spinner');
        return await fetch(ENDPOINT_URL + path, {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + state.token
            }
        }).then((response) => {
            window.emitter.emit('hide_spinner');
            if (response.status === 200) {
                return response.blob();
            } else if (response.status === 401 || response.status === 403) {
                router.push('/login');
            } else {
                throw new Error(`Cannot download file (${response.status})`);
            }
            return null;
        }).then((blob) => {
            return blob;
        });
    } else {
        await router.push('/login');
        return [];
    }
}

